import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import adminAPi from "../../../../utils/adminAPi";
import { FeAutocomplete } from "../../../FormElements";
import FeTextField from "../../../FormElements/FeTextField";

import { debounce, isEmpty } from "lodash";
import { updateObjectValue } from "../../../../utils/helper";

const bloodGroupOptions = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

const PatientDemography = ({ onStateChange, editedObj, pObj, updatedObj }) => {
  const [disList, setDisList] = useState([]);
  const [thanaList, setThanaList] = useState([]);
  const [socioList, setSocioList] = useState([]);
  const [contraList, setContraList] = useState([]);
  const [breastList, setBreastList] = useState([]);
  const disRef = useRef(null);
  const thanaRef = useRef(null);
  const [thanaName, setThanaName] = useState(null);
  const [disName, setDisName] = useState(null);
  const [disValue, setDisValue] = useState(null);
  const [thanaValue, setThanaValue] = useState(null);
  const [obj, setObj] = useState({
    marital_status: null,
    blood_group: null,
    area: null,
    police_station: null,
    district: null,
    socio_economic_status: null,
    family_member: null,
    age_of_marriage: null,
    age_of_first_child: null,
    contraceptive_method: null,
    age_of_menarche: null,
    age_of_menopause: null,
    regular_irregular_menstruation: null,
    breast_examination: null,
    breast_examination_value: null,
    exercise_per_week: null,
    dietary_habit: null,
    height: null,
    weight: null,
    bmi: null,
    h_o_alcoholism: null,
    h_o_hrt: null,
    breastfeeding_duration: null,
    rt_to_chest: null,
    cancer_history: null,
    known_mutation: null,
    smoking_packets_per_year: null,
    smoking_history: null,
    type: null,
  });
  console.log("DIS", disValue, thanaValue);
  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);
  // useEffect(() => {
  //   console.log("UPDATED CALLED Before", updatedObj);
  //   if (updatedObj) {
  //     console.log("UPDATED CALLED", updatedObj);
  //     // console.log("aaaaa", updateObjectValue(obj, updatedObj));
  //     // setObj(updateObjectValue(obj, updatedObj));
  //   }
  // }, [updatedObj]);

  // console.log("UPDATED OBJ", obj);
  useEffect(() => {
    if (pObj) {
      const p = updateObjectValue(obj, pObj);

      setObj(p);
      if (updatedObj) {
        const u = updateObjectValue(p, updatedObj);

        setTimeout(() => {
          setObj(u);

          if (u.district) {
            setDisValue({ district: u.district });
          }
          if (u.police_station) {
            setThanaValue({
              district: u.district,
              name: u.police_station,
            });
          }
        }, 100);
      }

      // setObj(updateObjectValue(obj, pObj));
      // setObj(Object.assign({}, obj, pObj));
    }
  }, [pObj, updatedObj]);

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  const changeFormValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log("VVVVV", value, name);
    setObj({ ...obj, [name]: value });
  };

  useEffect(() => {
    getDisList(disName);

    return () => {};
  }, [disName]);
  useEffect(() => {
    getThanaList(disName, thanaName);

    return () => {};
  }, [disName, thanaName]);

  const getDisList = (name) => {
    adminAPi
      .get(`admin/districts?district=${name ? name : ""}`)
      .then((response) => {
        setDisList(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getThanaList = (disName, thanaName) => {
    console.log("DISSS", disName, thanaName);
    if (disName)
      adminAPi
        .get(
          `admin/police-stations?district_name=${disName ? disName : ""}&name=${
            thanaName ? thanaName : ""
          }`
        )
        .then((response) => {
          console.log("response", response.data.data);
          setThanaList(response.data.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
  };
  useEffect(() => {
    getSocioStatus();
    getContraList();
    getBreastExamList();

    return () => {};
  }, []);

  async function getSocioStatus(id) {
    const { data } = await adminAPi.get(
      `admin/socio-economic-status-records-all`
    );
    setSocioList(data.data);
    console.log("DKDSJDFS", data);
  }

  async function getContraList(id) {
    const { data } = await adminAPi.get(
      `admin/contraceptive-method-records-all`
    );
    setContraList(data.data);
  }

  async function getBreastExamList(id) {
    const { data } = await adminAPi.get(
      `admin/breast-examination-value-records-all`
    );
    setBreastList(data.data);
  }

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  return (
    <>
      {" "}
      <span style={{ display: "none" }}>{JSON.stringify(obj)}</span>
      <Grid item sm={4}>
        <FeAutocomplete
          //  disabled={editedObj}
          label="Marital Stattus"
          value={obj.marital_status}
          options={["Unmarried", "Married", "Divorced", "Widowed"]}
          onChange={(event, v) => {
            const name = "marital_status";
            const value = v;
            let e = { target: { name, value } };
            changeFormValue(e);
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <FeAutocomplete
          //  disabled={editedObj}
          label="Blood Group"
          value={obj.blood_group}
          options={bloodGroupOptions}
          onChange={(event, v) => {
            const name = "blood_group";
            const value = v;
            let e = { target: { name, value } };
            changeFormValue(e);
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="area"
          label="Area"
          value={obj.area}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            freeSolo
            //  disabled={editedObj}
            forcePopupIcon={true}
            value={disValue}
            key={disValue}
            // disabled={!divId}
            options={disList}
            onChange={(e, v) => {
              if (!v) {
                setDisValue(v);
                setThanaValue(v);
                setThanaName(v);
                handleChangeInput("district", v);
                handleChangeInput("police_station", v);
                getDisList(null);

                return;
              }
              setThanaValue(null);
              setDisValue(v);
              disRef.current = v;
              setDisName(v.district);

              handleChangeInput("district", v.district);

              getThanaList(v.district, null);
            }}
            onInputChange={debounce((e, v, r) => {
              if (r === "input") {
                setDisName(v);
              } else if (r === "clear") {
              }
            }, 1000)}
            getOptionLabel={(option) => {
              return option.district || "";
            }}
            id="ConsultantPrimaryPhisician"
            label="District"
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            forcePopupIcon={true}
            freeSolo
            // disabled={!disId}
            value={thanaValue}
            key={thanaValue}
            options={thanaList}
            onChange={(e, v) => {
              if (!v) {
                setThanaValue(v);
                handleChangeInput("police_station", v);
                getThanaList(disName, null);

                return;
              }
              setThanaValue(v);
              handleChangeInput("police_station", v.name);
            }}
            onInputChange={debounce((e, v, r) => {
              if (r === "input") {
                console.log("VVVV", v);
                setThanaName(v);
              } else if (r === "clear") {
              }
            }, 1000)}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
            id="ConsultantPrimaryPhisician"
            label="Thana"
          />
        </Box>
      </Grid>
      <Grid item sm={4}>
        <FeAutocomplete
          //  disabled={editedObj}
          label="Socio-Economic Status"
          value={obj.socio_economic_status}
          options={socioList.map((o) => o.name)}
          onChange={(event, v) => {
            const name = "socio_economic_status";
            const value = v;
            let e = { target: { name, value } };

            changeFormValue(e);
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="family_member"
          label="No of Family Member"
          value={obj.family_member}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="exercise_per_week"
          label="Hours of Physical Exercise/week"
          value={obj.exercise_per_week}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="dietary_habit"
          label="Dietary Habit"
          value={obj.dietary_habit}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="height"
          label="Height (cm)"
          value={obj.height}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="weight"
          label="Weight (kg)"
          value={obj.weight}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeAutocomplete
          //  disabled={editedObj}
          label="H/O Alcoholism"
          value={obj.h_o_alcoholism}
          options={["Yes", "No"]}
          onChange={(event, v) => {
            const name = "h_o_alcoholism";
            const value = v;
            let e = { target: { name, value } };
            changeFormValue(e);
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="h_o_hrt"
          label="Any H/O HRT"
          value={obj.h_o_hrt}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="rt_to_chest"
          label="Any H/O RT to chest"
          value={obj.rt_to_chest}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="cancer_history"
          label="Personal/Family H/) of Cancer"
          value={obj.cancer_history}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          //  disabled={editedObj}
          name="known_mutation"
          label="Any Known Mutation"
          value={obj.known_mutation}
          onChange={changeFormValue}
        />
      </Grid>
      <Grid item sm={4}>
        <FeAutocomplete
          //  disabled={editedObj}
          label="Type"
          value={obj.type}
          options={["Local", "Foreign", "Other"]}
          onChange={(event, v) => {
            const name = "type";
            const value = v;
            let e = { target: { name, value } };
            changeFormValue(e);
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <FeAutocomplete
          //  disabled={editedObj}
          label="Smoking History"
          value={obj.smoking_history}
          options={["Yes", "No", "Former Smoker"]}
          onChange={(event, v) => {
            const name = "smoking_history";
            const value = v;
            let e = { target: { name, value } };
            changeFormValue(e);
          }}
        />
      </Grid>
      <Grid item sm={4}>
        <FeTextField
          type="number"
          //  disabled={editedObj}
          name="smoking_packets_per_year"
          label="No. of Packs / Year"
          value={obj.smoking_packets_per_year}
          onChange={changeFormValue}
        />
      </Grid>
    </>
  );
};

export default PatientDemography;
