import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import adminAPi from "../../../../utils/adminAPi";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";

const MolecularForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [list, setlist] = useState([]);
  const [exonList, setExonList] = useState([]);
  const [obj, setObj] = useState({
    molecular_pathologies: [
      {
        method: "",
        exon: "",
        status: "",
        gene: "",
        date: null,
      },
    ],
  });

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      if (e.molecular_pathologies.length === 0) {
        setObj({
          molecular_pathologies: [
            {
              method: "",
              exon: "",
              status: "",
              gene: "",
              date: null,
            },
          ],
        });
        return;
      }
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  const addDiagnosisField = () => {
    setObj({
      ...obj,
      molecular_pathologies: [
        ...obj.molecular_pathologies,
        {
          method: "",
          exon: "",
          status: "",
          gene: "",
          date: null,
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.molecular_pathologies];
    rows.splice(index, 1);
    setObj({ ...obj, molecular_pathologies: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.molecular_pathologies];
    list[index][name] = value;
    setObj({ ...obj, molecular_pathologies: list });
  };

  useEffect(() => {
    getList();
    return () => {};
  }, []);

  const getList = async () => {
    try {
      const { data } = await adminAPi.get(
        "admin/molecular-pathology-records-all"
      );
      console.log("SSS", data);
      setlist(data.data);
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  console.log("exonList", exonList);
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={1}>
          <Grid mb={1} item sm={4}>
            Molecular Pathology{" "}
          </Grid>
        </Grid>

        {obj.molecular_pathologies.map((data, index) => {
          const { gene, exon, status, method, date } = data;

          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={4} md={4}>
                <Box>
                  <FeAutocomplete
                    label="Method"
                    value={method}
                    options={list
                      .filter((d) => d.group === "method")
                      .map((d) => d.name)}
                    onChange={(e, v) => {
                      if (!v) {
                        handleChangeDiagnosis(index, "method", v);
                        return;
                      }
                      handleChangeDiagnosis(index, "method", v);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                  <FeAutocomplete
                    label="Gene"
                    getOptionLabel={(option) => option.name}
                    value={{ name: gene }}
                    options={list.filter((d) => d.group === "gene")}
                    onChange={async (e, v) => {
                      console.log("V", v);

                      if (!v) {
                        handleChangeDiagnosis(index, "gene", v);
                        return;
                      }
                      handleChangeDiagnosis(index, "gene", v.name);
                      try {
                        const { data } = await adminAPi.get(
                          `admin/exon-records-all?molecular_pathology_record_id=${v.id}`
                        );
                        const exist = [...exonList];
                        exist[index] = {
                          ...exist[index],
                          list: data.data.map((d) => d.value),
                        };

                        setExonList(exist);
                      } catch (error) {
                        console.log("EE", error.response);
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                  <FeAutocomplete
                    label="Exon"
                    value={exon}
                    options={
                      exonList && exonList[index] ? exonList[index].list : []
                    }
                    onChange={(e, v) => {
                      if (!v) {
                        handleChangeDiagnosis(index, "exon", v);
                        return;
                      }
                      handleChangeDiagnosis(index, "exon", v);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                  <FeAutocomplete
                    label="Status"
                    value={status}
                    options={list
                      .filter((d) => d.group === "status")
                      .map((d) => d.name)}
                    onChange={(e, v) => {
                      if (!v) {
                        handleChangeDiagnosis(index, "status", v);
                        return;
                      }
                      handleChangeDiagnosis(index, "status", v);
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={3} md={3} mb={1}>
                <FeDesktopDatePicker
                  label=" Date"
                  inputFormat="DD/MM/YYYY"
                  name="date"
                  value={date}
                  //  disabled={editedObj}
                  onChange={(val) => {
                    handleChangeDiagnosis(
                      index,
                      "date",
                      dayjs(val).format("YYYY-MM-DD")
                    );
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                {obj.molecular_pathologies.length !== 1 && (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    color="error"
                    //  disabled={editedObj}
                    onClick={() => removeDiagnosisFields(index)}
                  >
                    <DeleteForeverIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="success"
            //  disabled={editedObj}
            onClick={addDiagnosisField}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MolecularForm;
