import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import adminAPi from "../../../../utils/adminAPi";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";

const RadiologicalResponseRate = ({ editedObj, onStateChange, updatedObj }) => {
  const [obj, setObj] = useState({
    target_lasion: null,
    non_target_lasion: null,
    new_lasion: null,
    type: "Radiological",
  });
  const [formObj, setFormObj] = useState({
    radiological_response_rate_target_lasion: "",
    radiological_response_rate_non_target_lasion: "",
    radiological_response_rate_new_lasion: "",
    radiological_response_rate_result: "",
    radiological_response_rate_date: null,
  });

  const [t, setT] = useState(null);
  const [n, setN] = useState(null);
  const [m, setM] = useState(null);
  const [clinicalValue, setClinicalValue] = useState(null);

  const [target_lasion, settarget_lasion] = useState([]);
  const [non_target_lasion, setnon_target_lasion] = useState([]);
  const [new_lasion, setnew_lasion] = useState([]);
  const [list, setlist] = useState([]);
  const [params, setParams] = useState({
    per_page: 200,
    page: 1,
    type: "Radiological",
  });

  useEffect(() => {
    getList();
    return () => {};
  }, [params]);

  const getList = async () => {
    try {
      const { data } = await adminAPi.get(
        "admin/response-rate-records",
        params
      );
      console.log("SSS", data);
      setlist(data.data.data);
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  useEffect(() => {
    gettarget_lasion();
    getnon_target_lasion();
    getnew_lasionl();
    return () => {};
  }, []);
  const gettarget_lasion = async () => {
    const { data } = await adminAPi.get(
      "admin/response-rate-records/radiological/target_lasion"
    );
    console.log("DDDDAAA", data);
    settarget_lasion(data.data);
  };
  const getnon_target_lasion = async () => {
    const { data } = await adminAPi.get(
      "admin/response-rate-records/radiological/non_target_lasion"
    );
    console.log("DDDDAAA", data);
    setnon_target_lasion(data.data);
  };
  const getnew_lasionl = async () => {
    const { data } = await adminAPi.get(
      "admin/response-rate-records/radiological/new_lasion"
    );
    console.log("new_lasion", data);
    setnew_lasion(data.data);
  };
  useEffect(() => {
    if (!isEmpty(formObj)) onStateChange(formObj);
  }, [formObj]);
  function updateObject(firstObj, secondObj) {
    Object.keys(firstObj).forEach((key) => {
      if (secondObj.hasOwnProperty(key)) {
        firstObj[key] = secondObj[key];
      }
    });
    return firstObj;
  }
  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObject(formObj, updatedObj);
    }
    if (editedObj) {
      e = updateObject(formObj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setFormObj(u);
        setObj({
          ...obj,
          target_lasion: u.radiological_response_rate_target_lasion,
          non_target_lasion: u.radiological_response_rate_non_target_lasion,
          new_lasion: u.radiological_response_rate_new_lasion,
        });
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        setFormObj(e);
        setObj({
          ...obj,
          target_lasion: e.radiological_response_rate_target_lasion,
          non_target_lasion: e.radiological_response_rate_non_target_lasion,
          new_lasion: e.radiological_response_rate_new_lasion,
        });
      }, 100);
    }
  }, [updatedObj, editedObj]);
  useEffect(() => {
    if (
      !isEmpty(obj.target_lasion) ||
      !isEmpty(obj.non_target_lasion) ||
      !isEmpty(obj.new_lasion)
    ) {
      getApi(obj);
    }
  }, [obj]);

  const getApi = async (param) => {
    try {
      const { data } = await adminAPi.post(
        "admin/response-rate-calculation-result",
        param
      );
      setClinicalValue(data.data?.result);
      setFormObj({
        ...formObj,
        radiological_response_rate_result: data.data?.result,
      });
    } catch (error) {
      setClinicalValue(null);
      setFormObj({
        ...formObj,
        radiological_response_rate_result: null,
      });
    }
  };

  const handleChangeInputResult = (name, value) => {
    setObj({
      ...obj,
      [name]: value,
    });
  };
  const handleChangeInputForm = (name, value) => {
    setFormObj({
      ...formObj,
      [name]: value,
    });
  };

  return (
    <>
      {" "}
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            iRECIST
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            value={formObj.radiological_response_rate_target_lasion}
            options={target_lasion.map((o) => o.data)}
            id="T"
            label="target_lesion"
            onChange={(e, v) => {
              setT(v);
              if (v) {
                handleChangeInputResult("target_lasion", v);
                handleChangeInputForm(
                  "radiological_response_rate_target_lasion",
                  v
                );
              } else {
                handleChangeInputResult("target_lasion", v);
                handleChangeInputForm(
                  "radiological_response_rate_target_lasion",
                  v
                );
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            value={formObj.radiological_response_rate_non_target_lasion}
            options={non_target_lasion.map((o) => o.data)}
            id="N"
            label="non_target_lesion"
            onChange={(e, v) => {
              setN(v);
              if (v) {
                handleChangeInputResult("non_target_lasion", v);
                handleChangeInputForm(
                  "radiological_response_rate_non_target_lasion",
                  v
                );
              } else {
                handleChangeInputResult("non_target_lasion", v);
                handleChangeInputForm(
                  "radiological_response_rate_non_target_lasion",
                  v
                );
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            value={formObj.radiological_response_rate_new_lasion}
            options={new_lasion.map((o) => o.data)}
            id="M"
            label="new_lesion"
            onChange={(e, v) => {
              setM(v);
              if (v) {
                handleChangeInputResult("new_lasion", v);
                handleChangeInputForm(
                  "radiological_response_rate_new_lasion",
                  v
                );
              } else {
                handleChangeInputResult("new_lasion", v);
                handleChangeInputForm(
                  "radiological_response_rate_new_lasion",
                  v
                );
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box mb={2}>
          <FeDesktopDatePicker
            label="date"
            inputFormat="DD/MM/YYYY"
            value={formObj.radiological_response_rate_date}
            onChange={(val) => {
              if (val)
                setFormObj({
                  ...formObj,
                  radiological_response_rate_date:
                    dayjs(val).format("YYYY-MM-DD"),
                });
              else {
                setFormObj({
                  ...formObj,
                  radiological_response_rate_date: null,
                });
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            value={formObj?.radiological_progression_site}
            options={list
              .filter((o) => o.group === "Progression site")
              .map((o) => o.value)}
            id="M"
            label="Progression site"
            onChange={(e, v) => {
              if (v) {
                handleChangeInputForm("radiological_progression_site", v);
              } else {
                handleChangeInputForm("radiological_progression_site", v);
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            value={formObj?.radiological_method_of_estimation}
            options={list
              .filter((o) => o.group === "Method of Estimation")
              .map((o) => o.value)}
            id="M"
            label="Method of Estimation"
            onChange={(e, v) => {
              if (v) {
                handleChangeInputForm("radiological_method_of_estimation", v);
              } else {
                handleChangeInputForm("radiological_method_of_estimation", v);
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <Grid item sm={6}>
            <FeTextField
              key={formObj.radiological_response_rate_result}
              label="radiological_response_rate_result"
              value={formObj.radiological_response_rate_result}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default RadiologicalResponseRate;
